import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import http from "services/http";
import api from "services/api";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";
import CourseAssessorCertificateDataTable from "./CourseAssessorCertificateDataTable";
import { useForm } from "react-hook-form";

export default function ListCourseAssessorCertificatePage() {
  const dispatch = useDispatch();

  const logoRef = useRef(null);

  const { register, setValue, handleSubmit } = useForm({
    defaultValues: {
      assessor: {
        name: "",
        logo: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [courseAssessor, setCourseAssessor] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalCourseAssessor, setTypeModalCourseAssessor] =
    useState("CREATE");
  const [showModalCourseAssessor, setShowModalCourseAssessor] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(api.v1.admin.course.assessor, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseAssessor(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(`${api.v1.admin.course.assessor}/${id}`);

      setImageSrc(data.data?.logo);
      fetch(data.data?.logo + `?cachebuster=${new Date().getTime()}`).then(
        async res => {
          const blob = await res.blob();
          const file = new File([blob], "logo.jpg", { type: blob.type });
          setValue("assessor", {
            id,
            logo: [file],
            name: data.data.name,
          });
        }
      );

      setTypeModalCourseAssessor("UPDATE");
      setShowModalCourseAssessor(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { assessor } = payload;

      const formData = new FormData();
      formData.append("name", assessor.name || "");
      formData.append("logo", assessor.logo[0]);

      await http.post(api.v1.admin.course.assessor, formData);

      handleCloseModal();

      Swal.fire("Sukses", "Asesor berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { assessor } = payload;

      const formData = new FormData();
      formData.append("name", assessor.name || "");
      formData.append("logo", assessor.logo[0]);

      await http.put(
        `${api.v1.admin.course.assessor}/${assessor.id}`,
        formData
      );

      handleCloseModal();

      Swal.fire("Sukses", "Asesor berhasil di perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.course.assessor}/${id}`);

      Swal.fire("Sukses", "Asesor berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Asesor",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleOpenModal = async () => {
    setTypeModalCourseAssessor("CREATE");
    setShowModalCourseAssessor(true);
  };

  const handleCloseModal = () => {
    setShowModalCourseAssessor(false);
    setImageSrc();
    setValue("assessor", {
      name: "",
      logo: "",
    });
  };

  const registerLogoField = register("assessor.logo", {
    required: "Logo tidak boleh kosong",
  });

  const onUploadLogo = () => logoRef.current.click();

  const onLogoChange = e => {
    if (e.target.files) {
      setValue("assessor.logo", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Asesor Kelas</title>
      </Helmet>

      <DefaultHeader section="Asesor Kelas" />

      <Container maxWidth={false} component={Box} id="course-assessor">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(true)}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <CourseAssessorCertificateDataTable
                      loading={loadingData}
                      data={courseAssessor}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Course Assessor */}
                <Dialog
                  open={showModalCourseAssessor}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Asesor Kelas</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalCourseAssessor === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        {/* name */}
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("assessor.name")}
                            />
                          </FormGroup>
                        </Grid>

                        {/* logo */}
                        <Grid item sm={4} xs={8}>
                          <FormGroup>
                            <FormLabel>Logo</FormLabel>
                            <Button
                              variant="contained"
                              onClick={() => onUploadLogo()}
                            >
                              {typeModalCourseAssessor === "CREATE"
                                ? "Tambah Logo"
                                : "Ubah Logo"}
                            </Button>
                            <input
                              style={{ display: "none" }}
                              onChange={e => {
                                registerLogoField.onChange(e);
                                onLogoChange(e);
                              }}
                              accept="image/*"
                              type="file"
                              ref={logoRef}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={8} xs={4}></Grid>
                        <Grid item sm={12} className="mb-4">
                          {imageSrc && (
                            <Box
                              component="img"
                              sx={{
                                width: 100,
                                maxWidth: { xs: 50, md: 100 },
                                borderRadius: "16px",
                              }}
                              alt="Banner."
                              src={imageSrc}
                            />
                          )}
                        </Grid>

                        {/* button */}
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalCourseAssessor === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
