import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import BrandDataTable from "./BrandDataTable";

export default function ListBrand() {
  const dispatch = useDispatch();
  const logoRef = useRef(null);
  const iconRef = useRef(null);
  const { register, setValue, handleSubmit, reset } = useForm({
    defaultValues: {
      brand: {
        name: "",
        logo: "",
        icon: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [iconSrc, setIconSrc] = useState(null);

  const [brandData, setBrandData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalBrand, setTypeModalBrand] = useState("CREATE");
  const [showModalBrand, setShowModalBrand] = useState(false);

  /**
   * Fetch Data
   */

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.brand.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setBrandData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(`${api.v1.admin.brand.main}/${id}`);

      const { logo, name, icon } = data.data;

      setImageSrc(logo);
      setIconSrc(icon);

      setValue("brand.id", id);
      setValue("brand.name", name);

      await fetch(logo + `?cachebuster=${new Date().getTime()}`).then(
        async res => {
          const blob = await res.blob();
          const file = new File([blob], "logo.jpg", { type: blob.type });

          setValue("brand.logo", [file]);
        }
      );

      await fetch(icon + `?cachebuster=${new Date().getTime()}`).then(
        async res => {
          const blob = await res.blob();
          const file = new File([blob], "icon.jpg", { type: blob.type });

          setValue("brand.icon", [file]);
        }
      );

      setTypeModalBrand("UPDATE");
      setShowModalBrand(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { brand } = payload;

      const formData = new FormData();
      formData.append("name", brand.name);
      formData.append("icon", brand.icon[0]);
      formData.append("logo", brand.logo[0]);

      await http.post(api.v1.admin.brand.main, formData);

      handleCloseModal();

      Swal.fire("Sukses", "Data brand berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { brand } = payload;

      const formData = new FormData();
      formData.append("name", brand.name);
      formData.append("logo", brand.logo[0]);
      formData.append("icon", brand.icon[0]);

      await http.put(`${api.v1.admin.brand.main}/${brand.id}`, formData);

      handleCloseModal();

      Swal.fire("Sukses", "Data brand berhasil di perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.brand.main}/${id}`);

      Swal.fire("Sukses", "Data Metode Pembelian di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = () => {
    reset();
    setImageSrc();
    setIconSrc();
    setTypeModalBrand("CREATE");
    setShowModalBrand(true);
  };

  const handleCloseModal = () => {
    setShowModalBrand(false);
    setImageSrc();
    setIconSrc();
    reset();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus brand",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const registerLogoField = register("brand.logo", {
    required: "Cover tidak boleh kosong",
  });
  const onUploadLogo = () => logoRef.current.click();
  const onLogoChange = e => {
    if (e.target.files) {
      setValue("brand.logo", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  const registerIconField = register("brand.icon", {
    required: "Cover tidak boleh kosong",
  });

  const onUploadIcon = () => iconRef.current.click();
  const onIconChange = e => {
    if (e.target.files) {
      setValue("brand.icon", e.target.files);
      setIconSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Brand</title>
      </Helmet>

      <DefaultHeader section="Data Brand" />

      <Container maxWidth={false} component={Box} id="payment-method">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <BrandDataTable
                      loading={loadingData}
                      data={brandData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Brand */}
                <Dialog
                  open={showModalBrand}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Brand</h2>
                    </Box>
                    <form
                      onSubmit={
                        typeModalBrand === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("brand.name")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <FormGroup>
                            <FormLabel>Logo</FormLabel>
                            <Button
                              variant="contained"
                              onClick={() => onUploadLogo()}
                            >
                              {typeModalBrand === "CREATE"
                                ? "Tambah Logo"
                                : "Ubah Logo"}
                            </Button>
                            <input
                              style={{ display: "none" }}
                              onChange={e => {
                                registerLogoField.onChange(e);
                                onLogoChange(e);
                              }}
                              accept="image/*"
                              type="file"
                              ref={logoRef}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid itemsm={12} xs={12}></Grid>
                        <Grid item sm={12} className="mb-4">
                          {imageSrc && (
                            <Box
                              component="img"
                              sx={{
                                // height: 240,
                                // width: 420,
                                maxHeight: { xs: 240, md: 240 },
                                maxWidth: { xs: 240, md: 420 },
                              }}
                              alt="Logo."
                              src={imageSrc}
                            />
                          )}
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <FormGroup>
                            <FormLabel>Icon</FormLabel>
                            <Button
                              variant="contained"
                              onClick={() => onUploadIcon()}
                            >
                              {typeModalBrand === "CREATE"
                                ? "Tambah Icon"
                                : "Ubah Icon"}
                            </Button>
                            <input
                              style={{ display: "none" }}
                              onChange={e => {
                                registerIconField.onChange(e);
                                onIconChange(e);
                              }}
                              accept="image/*"
                              type="file"
                              ref={iconRef}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={12} xs={12}></Grid>
                        <Grid item sm={12} className="mb-4">
                          {iconSrc && (
                            <Box
                              component="img"
                              sx={{
                                // height: 240,
                                // width: 420,
                                maxHeight: { xs: 240, md: 240 },
                                maxWidth: { xs: 240, md: 420 },
                              }}
                              alt="Icon."
                              src={iconSrc}
                            />
                          )}
                        </Grid>

                        <Grid item sm={12} xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalBrand === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
